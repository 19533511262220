import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/TalkNoteLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Dan Questions`}</strong>{`:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`How will you deliver the idea to the audience?`}</p>
        <ul parentName="li">
          <li parentName="ul">{`I’ll tell a proper story. It has a hero and a villain.`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`What is the one thing that you want people to take away from your talk?`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Don’t be afraid to say “Domain Specific Language” — you probably used a
lot of them.`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Why are you giving the talk? What is the emotional core? What do you
believe in?`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Sometimes you need to build the right tool for the job.`}</li>
        </ul>
      </li>
    </ul>
    <p><strong parentName="p">{`Outline`}</strong>{`:`}</p>
    <ul>
      <li parentName="ul">{`Introduce the hero and the villain.`}</li>
      <li parentName="ul">{`The villain destroys almost all colors in the world.`}</li>
      <li parentName="ul">{`Enter constraint-based design.`}</li>
      <li parentName="ul">{`We want to make illegal states unrepresentable.`}</li>
      <li parentName="ul">{`Emotion’s CSS objects instead of CSS strings.`}<ul parentName="li">
          <li parentName="ul">{`We can work with this.`}</li>
        </ul></li>
      <li parentName="ul">{`Enter `}<inlineCode parentName="li">{`sx`}</inlineCode>{` prop. We’re not in CSS-land anymore.`}</li>
      <li parentName="ul">{`Let’s make its types stricter.`}<ul parentName="li">
          <li parentName="ul">{`We preserve runtime behavior. It’s useful for prototyping, but you don’t
want to merge arbitrary values to the main branch.`}</li>
        </ul></li>
    </ul>
    <hr></hr>
    <p><strong parentName="p">{`Disclaimer:`}</strong>{` The public API of `}<inlineCode parentName="p">{`theme-ui`}</inlineCode>{` presented in code snippets
doesn’t reflect current production. It’s taken from a WIP open pull request.`}</p>
    <h2 {...{
      "id": "the-hero-and-the-villain",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#the-hero-and-the-villain",
        "aria-label": "the hero and the villain permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`The hero and the villain`}</h2>
    <p>{`Every good story needs a hero, and a villain. The heroes of this one are
TypeScript, and CSS-in-JS. The villain here, is a hyperbolized version of a
designer I may have happened to work with. Let’s name him… Sergey.`}</p>
    <p>{`A typical UI/UX designer. Pretty serious guy, although he enjoys his
fashionable streetwear. Thick Russian accent.`}</p>
    <p><a parentName="p" {...{
        "href": "https://ilarge.lisimg.com/image/7159931/850full.jpg"
      }}>{`He looks more or less like this.`}</a></p>
    <p>{`Sergey was responsible for the look and feel of the product we were
building, so when he told me`}</p>
    <blockquote>
      <p parentName="blockquote">{`“Piotr. There is no ‘hotpink’ and no ‘lightblue’. There are only four base
colors: `}<em parentName="p">{`primary`}</em>{`, `}<em parentName="p">{`background`}</em>{`, `}<em parentName="p">{`text`}</em>{` and `}<em parentName="p">{`accent`}</em>{`. Each of them has one
lighter and one darker shade.”`}</p>
    </blockquote>
    <p>{`I knew we were going to partake in constraint-based design. Sergey declared
similar things about spacing between components and typography, so…`}</p>
    <p>{`if I use padding of six pixels while implementing the UI, Sergey will know.
He has a pixel-perfect vision! He will call me on Slack, and tell me “Piotr,
this is entirely messed up.”, sometimes with words taken straight of your
last Counter Strike game.`}</p>
    <p>{`As many of you, I am a fan of designing with types, and making illegal
states unrepresentable.`}</p>
    <p>{`I want to avoid making Sergey angry, but CSS barely helps me. Okay, I can
use CSS variables, which are still not supported in Internet Explorer, but
it doesn’t forbid me from using arbitrary values like “hotpink” or “6
pixels”. TypeScript, however, is really good at this kind of stuff.`}</p>
    <p>{`This leads us to the topic of our talk. CSS is a domain specific language
for styling, but not for my app on brand and according to Sergey’s designs!`}</p>
    <p>{`Fortunately, I don’t really write CSS syntax anymore, I use object literals
in TypeScript.`}</p>
    <pre {...{
      "className": "night-owl-no-italics vscode-highlight",
      "data-language": "tsx"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk6"
          }}>{`/** `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`@jsx`}</span><span parentName="span" {...{
            "className": "mtk6"
          }}>{` jsx */`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`import`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` { jsx } `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`from`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{`@emotion/core`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`import`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` { breakpoints } `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`from`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{`./theme`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`const `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`PrimaryBox`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` = `}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`()`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` => {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`  return `}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`<div`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk9"
          }}>{`      `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`css`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`=`}</span><span parentName="span" {...{
            "className": "mtk24"
          }}>{`{`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`{`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk9"
          }}>{`        width: `}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{`100px`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk9"
          }}>{`        height: `}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{`100px`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk9"
          }}>{`        backgroundColor: `}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{`#00f`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk9"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`[breakpoints`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`tablet`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`]`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`: {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk9"
          }}>{`          width: `}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{`200px`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk9"
          }}>{`          height: `}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{`200px`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk9"
          }}>{`        },`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk9"
          }}>{`      }`}</span><span parentName="span" {...{
            "className": "mtk24"
          }}>{`}`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk9"
          }}>{`    />`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`}`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`;`}</span></span></code></pre>
    <p>{`We can treat these object literals as an embedded domain specific language
for styling. An embedded DSL is a subset of a general purpose language we
use to solve problems from one specific domain. React is an embedded DSL in
JavaScript and TypeScript, designed for building interactive apps,
describing their interfaces and state in a declarative manner.`}</p>
    <p>{`Enter Theme UI, which looks more or less like these 3 potatoes.`}</p>
    <p>{`Theme UI builds up on top of Emotion and allows us to use our design tokens
as first class citizens.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1098px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "73.62318840579711%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsSAAALEgHS3X78AAAB+ElEQVQoz3VTB27cMBDU/59pq7Nz2Zs8vEsc2EAIQQC3zs4sl+fnGc+IJeWa56WVEXXPNM3PU7XO+x7XdaT0Ch3Lr+Q22mEv4eX0ljAce6J6epsu74kxy3k05mnTsowxQggxRlz6Myg477whG3PsJZbagg/R6eic5vxj2677FlL2lEYpi9YwciGEc856Wtd1P/b9PM9rtVoiVErp4TGKpEQk4g06o1POi1Lquq592xhnmNZaiypCSKWZYNe6bvB+fq6jpWCNUSqh55gUjNaW1ppUEsUAt/YKK0aorVkrz327b4ZapZTespUMUP9R2/skDFOVUl3yQAhUiAYJ6NxbAUTGWM5ZSuEUD5bebf90nhrUapyFDZPvmPc6GefGCEcm5wKc930f20f21FIeU6OZ30EYhDXOCCkmhBC892iurS3ZA9k7FGK0HLw1mgtgQRisIHyh6EAJsIEqxKWYMLAmrcVFkEdrzFIx6ig1RugghQCuinzAhjzHcSAC2FAyl6zJJE9Wq/04GJtStZo7QEsFSVFOcd5fS7bEmkAvYSvwR44HJb3miV/MUCWl8kaSNYkI2uJfXxs1kytEaKW08voDYCUN7ZgxFitA2DYlSnTf8jz4/p4fu/2mMZDm7DqvG+tVSx41frt/PYTfD+NdnxRzWgTCk8i4Pv85X96sYWPO5USdAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "theme ui potatoes",
          "title": "theme ui potatoes",
          "src": "/static/90ecadfbbc75daeb252eb058bfc32c34/99661/theme-ui-potatoes.png",
          "srcSet": ["/static/90ecadfbbc75daeb252eb058bfc32c34/e4d6b/theme-ui-potatoes.png 345w", "/static/90ecadfbbc75daeb252eb058bfc32c34/1e043/theme-ui-potatoes.png 690w", "/static/90ecadfbbc75daeb252eb058bfc32c34/99661/theme-ui-potatoes.png 1098w"],
          "sizes": "(max-width: 1098px) 100vw, 1098px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <pre {...{
      "className": "night-owl-no-italics vscode-highlight",
      "data-language": "tsx"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk6"
          }}>{`/** `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`@jsx`}</span><span parentName="span" {...{
            "className": "mtk6"
          }}>{` jsx */`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`import`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` { jsx } `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`from`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{`theme-ui`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`const `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`BlueBox`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` = `}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`()`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` => {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`  return `}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`<div`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk9"
          }}>{`      `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`sx`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`=`}</span><span parentName="span" {...{
            "className": "mtk24"
          }}>{`{`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`{`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk9"
          }}>{`        size:`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` [`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{`small`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{`medium`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`]`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk9"
          }}>{`        bg: `}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{`primary`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk9"
          }}>{`      }`}</span><span parentName="span" {...{
            "className": "mtk24"
          }}>{`}`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk9"
          }}>{`    />`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`}`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`;`}</span></span></code></pre>
    <p>{`Notice that this prop is no longer called `}<inlineCode parentName="p">{`css`}</inlineCode>{`, and the responsive tuple
syntax instead of media query.
`}<a parentName="p" {...{
        "href": "https://youtu.be/vQLNS3HWfCM"
      }}>{`Toto, I’ve a feeling we’re not in CSS-land anymore.`}</a></p>
    <p>{`We’re almost there. However, Theme UI doesn’t forbid you from using
arbitrary values to allow prototyping.`}</p>
    <pre {...{
      "className": "night-owl-no-italics vscode-highlight",
      "data-language": "tsx"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk6"
          }}>{`/** `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`@jsx`}</span><span parentName="span" {...{
            "className": "mtk6"
          }}>{` jsx */`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`import`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` { jsx } `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`from`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`'`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{`theme-ui`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`'`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`const `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`BlueBox`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` = `}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`()`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` => {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`  return `}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`<div`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk9"
          }}>{`      `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`sx`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`=`}</span><span parentName="span" {...{
            "className": "mtk24"
          }}>{`{`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`{`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk9"
          }}>{`        bg: `}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`'`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{`primry`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`'`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk6"
          }}>{`// typo`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk9"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`size`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`:`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` [`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`          `}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`'`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{`100px`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`'`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk6"
          }}>{`// arbitrary value`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`          `}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`'`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{`1234p`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`'`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk6"
          }}>{`// arbitrary value typo`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        ]`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk9"
          }}>{`      }`}</span><span parentName="span" {...{
            "className": "mtk24"
          }}>{`}`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk9"
          }}>{`	/>`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`}`}</span></span></code></pre>
    <p>{`Let’s see how can we change its types to implement “design tokens only”
mode.`}</p>
    <small>
      <p><em parentName="p">{`From this point onwards, we are not talking about current production Theme
UI.`}</em>{`
`}<em parentName="p"><a parentName="em" {...{
            "href": "https://github.com/system-ui/theme-ui/pull/1090"
          }}>{`See PR theme-ui#1090`}</a>{`.`}</em></p>
    </small>
    <p>{`So how can we tell a library about a type we defined in our repository? How
can we inject it?`}</p>
    <p>{`We do it with module augmentation`}</p>
    <pre {...{
      "className": "night-owl-no-italics vscode-highlight",
      "data-language": "ts"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`declare`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`module`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{`theme-ui`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {}`}</span></span></code></pre>
    <p>{`and declaration merging`}</p>
    <pre {...{
      "className": "night-owl-no-italics vscode-highlight",
      "data-language": "ts"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`const `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`myTheme`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` = `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`makeTheme`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`{`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`  colors: {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`    primary: `}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{`red`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{`primary-dark`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{`darkred`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`  },`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`}`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`);`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`type`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` MyTheme `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`=`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`typeof`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`myTheme`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`declare`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`module`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{`theme-ui`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`export`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`interface`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` UserTheme `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`extends`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`MyTheme`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {}`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span></code></pre>
    <p>{`But what is this weird UserTheme? It’s nothing! Yet…`}</p>
    <p>{`Let’s add it to `}<em parentName="p">{`@theme-ui/css`}</em>{` in `}<em parentName="p">{`src/types.ts`}</em>{`.`}</p>
    <pre {...{
      "className": "night-owl-no-italics vscode-highlight",
      "data-language": "ts"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk6"
          }}>{`/**`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk6"
          }}>{` * Can be augmented by users to inject their exact theme into Theme UI types.`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk6"
          }}>{` */`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`export`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`interface`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` UserTheme {}`}</span></span></code></pre>
    <p>{`Then, we need to extract the types of keys of our color scale (`}<inlineCode parentName="p">{`primary`}</inlineCode>{` and
`}<inlineCode parentName="p">{`primary-dark`}</inlineCode>{`).`}</p>
    <pre {...{
      "className": "night-owl-no-italics vscode-highlight",
      "data-language": "ts"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`export`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`interface`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` FinalTheme `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`extends`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`Assign`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`<`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`Theme`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`UserTheme`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`> {}`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`type`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` Defined<`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`T`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`> `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`=`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`Exclude`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`<`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`T`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`undefined`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`>;`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`type`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` Color `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`=`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`keyof`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`Defined`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`<`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`FinalTheme`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`[`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{`colors`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`]> `}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`|`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` CSS`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`Globals`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`;`}</span></span></code></pre>
    <p>{`where `}<inlineCode parentName="p">{`Assign`}</inlineCode>{` is a type-level sibling of `}<inlineCode parentName="p">{`Object.assign`}</inlineCode>{`, imported from
`}<a parentName="p" {...{
        "href": "https://github.com/piotrwitek/utility-types"
      }}><em parentName="a">{`utility-types`}</em></a>{` or defined as
so`}</p>
    <pre {...{
      "className": "night-owl-no-italics vscode-highlight",
      "data-language": "ts"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`export`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`type`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` Assign<`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`T`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`U`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`> `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`=`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  [`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`P`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`in`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`keyof`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` (`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`T`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`&`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`U`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)]`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`:`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`P`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`extends`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`keyof`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`U`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`?`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`U`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`[`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`P`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`]`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`:`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`P`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`extends`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`keyof`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`T`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`?`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`T`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`[`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`P`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`]`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`:`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`never`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`};`}</span></span></code></pre>
    <p>{`and add them to `}<inlineCode parentName="p">{`OverwriteCSSProperties`}</inlineCode>{` interface.`}</p>
    <pre {...{
      "className": "night-owl-no-italics vscode-highlight",
      "data-language": "ts"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`interface`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` OverwriteCSSProperties {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk6"
          }}>{`// other properties redacted for brevity`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk6"
          }}>{`/**`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk6"
          }}>{`   * The `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`**`}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`\``}</span><span parentName="span" {...{
            "className": "mtk5 mtkb"
          }}>{`z-index`}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`\``}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`**`}</span><span parentName="span" {...{
            "className": "mtk6"
          }}>{` CSS property sets the z-order of a positioned element and its descendants or flex items. Overlapping elements with a larger z-index cover those with a smaller one.`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk6"
          }}>{`   *`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk6"
          }}>{`   * `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`**`}</span><span parentName="span" {...{
            "className": "mtk5 mtkb"
          }}>{`Initial value`}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`**`}</span><span parentName="span" {...{
            "className": "mtk6"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`\`auto\``}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk6"
          }}>{`   *`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk6"
          }}>{`   * | Chrome | Firefox | Safari |  Edge  |  IE   |`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk6"
          }}>{`   * | :----: | :-----: | :----: | :----: | :---: |`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk6"
          }}>{`   * | `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`**`}</span><span parentName="span" {...{
            "className": "mtk5 mtkb"
          }}>{`1`}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`**`}</span><span parentName="span" {...{
            "className": "mtk6"
          }}>{`  |  `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`**`}</span><span parentName="span" {...{
            "className": "mtk5 mtkb"
          }}>{`1`}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`**`}</span><span parentName="span" {...{
            "className": "mtk6"
          }}>{`  | `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`**`}</span><span parentName="span" {...{
            "className": "mtk5 mtkb"
          }}>{`1`}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`**`}</span><span parentName="span" {...{
            "className": "mtk6"
          }}>{`  | `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`**`}</span><span parentName="span" {...{
            "className": "mtk5 mtkb"
          }}>{`12`}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`**`}</span><span parentName="span" {...{
            "className": "mtk6"
          }}>{` | `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`**`}</span><span parentName="span" {...{
            "className": "mtk5 mtkb"
          }}>{`4`}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`**`}</span><span parentName="span" {...{
            "className": "mtk6"
          }}>{` |`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk6"
          }}>{`   *`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk6"
          }}>{`   * `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`@see`}</span><span parentName="span" {...{
            "className": "mtk6"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`https://developer.mozilla.org/docs/Web/CSS/z-index`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk6"
          }}>{`   */`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  zIndex`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`?:`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` CSS`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`ZIndexProperty`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`|`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`string`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk6"
          }}>{`// here we go`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  color`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`?:`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`Color`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  backgroundColor`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`?:`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`Color`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  borderColor`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`?:`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`Color`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  caretColor`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`?:`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`Color`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span></code></pre>
    <p>{`and our `}<inlineCode parentName="p">{`color`}</inlineCode>{` values are typechecked 🎉`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "653px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "48.98550724637681%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAAsSAAALEgHS3X78AAABAElEQVQoz6WQyW7EIBBE5/8/MIdEySEjL4CNbRaDxzbkeYtGuSRSSgI11V1d3dy8H9tWS6lijN577mmawo5xHGHCBXgY7seOnPPNWt9t0BylVPcEIURd1wR930spjTFaa2Ja4LGJY3zwLoqCNDkETdMMw0AjYsiWwS4Qk3XO4b+JOdaasiypw4cKa21z4ZjF7aAXNgQ4z/N8jo0YpZKSYnJUHF0Qb7xSMMzMk0aY0/10ZiP20MaVQommM8ZiV1UVsxwyseOY6NiZX8R8E6d1ff0oXt7Lu+zePmuhaH+uR114AoLv4PywMYZg3BIYI+V1TSnlP+O2LMsPKl34XZz/gS9nED3/rdPs7wAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "2020 08 28 18 55 51",
          "title": "2020 08 28 18 55 51",
          "src": "/static/dc82ac66619f5ef4fe1e5ef6a308e68b/e7dce/2020-08-28-18-55-51.png",
          "srcSet": ["/static/dc82ac66619f5ef4fe1e5ef6a308e68b/e4d6b/2020-08-28-18-55-51.png 345w", "/static/dc82ac66619f5ef4fe1e5ef6a308e68b/e7dce/2020-08-28-18-55-51.png 653w"],
          "sizes": "(max-width: 653px) 100vw, 653px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <h2 {...{
      "id": "ending",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#ending",
        "aria-label": "ending permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`Ending`}</h2>
    <p>{`I’m no longer a pain in the ass for Sergey, and my styles are typesafe.`}</p>
    <hr></hr>
    <p>{`The last example in my live `}<a parentName="p" {...{
        "href": "https://youtu.be/-0smYzH1m3s?t=995"
      }}>{`demo`}</a>{`
obviously went wrong 😂`}</p>

    <style {...{
      "className": "vscode-highlight-styles"
    }}>{`
  
  .night-owl-no-italics {
background-color: #011627;
color: #d6deeb;
}

.night-owl-no-italics .mtk1 { color: #D6DEEB; }
.night-owl-no-italics .mtk2 { color: #011627; }
.night-owl-no-italics .mtk3 { color: #C792EA; }
.night-owl-no-italics .mtk4 { color: #82B1FF; }
.night-owl-no-italics .mtk5 { color: #ADDB67; }
.night-owl-no-italics .mtk6 { color: #637777; }
.night-owl-no-italics .mtk7 { color: #82AAFF; }
.night-owl-no-italics .mtk8 { color: #F78C6C; }
.night-owl-no-italics .mtk9 { color: #7FDBCA; }
.night-owl-no-italics .mtk10 { color: #FF5874; }
.night-owl-no-italics .mtk11 { color: #FFEB95; }
.night-owl-no-italics .mtk12 { color: #FFCB8B; }
.night-owl-no-italics .mtk13 { color: #7FDBCAFF; }
.night-owl-no-italics .mtk14 { color: #80CBC4; }
.night-owl-no-italics .mtk15 { color: #FF6363; }
.night-owl-no-italics .mtk16 { color: #57EAF1; }
.night-owl-no-italics .mtk17 { color: #5F7E97; }
.night-owl-no-italics .mtk18 { color: #ECC48D; }
.night-owl-no-italics .mtk19 { color: #B2CCD6; }
.night-owl-no-italics .mtk20 { color: #FAD430; }
.night-owl-no-italics .mtk21 { color: #FFFFFF; }
.night-owl-no-italics .mtk22 { color: #FF2C83; }
.night-owl-no-italics .mtk23 { color: #020E14; }
.night-owl-no-italics .mtk24 { color: #D3423E; }
.night-owl-no-italics .mtk25 { color: #EC5F67; }
.night-owl-no-italics .mtk26 { color: #8BD649; }
.night-owl-no-italics .mtk27 { color: #5CA7E4; }
.night-owl-no-italics .mtk28 { color: #A2BFFC; }
.night-owl-no-italics .mtk29 { color: #EF535090; }
.night-owl-no-italics .mtk30 { color: #ADDB67FF; }
.night-owl-no-italics .mtk31 { color: #697098; }
.night-owl-no-italics .mtk32 { color: #FF869A; }
.night-owl-no-italics .mtk33 { color: #DDDDDD; }
.night-owl-no-italics .mtk34 { color: #E0DEC6; }
.night-owl-no-italics .mtk35 { color: #CDEBF7; }
.night-owl-no-italics .mtk36 { color: #D9F5DD; }
.night-owl-no-italics .mtk37 { color: #6AE9F0; }
.night-owl-no-italics .mtk38 { color: #C789D6; }
.night-owl-no-italics .mtk39 { color: #BEC5D4; }
.night-owl-no-italics .mtk40 { color: #31E1EB; }
.night-owl-no-italics .mtk41 { color: #8EACE3; }
.night-owl-no-italics .mtk42 { color: #78CCF0; }
.night-owl-no-italics .mtk43 { color: #FAF39F; }
.night-owl-no-italics .mtk44 { color: #D7DBE0; }
.night-owl-no-italics .mtk45 { color: #7986E7; }
.night-owl-no-italics .mtki { font-style: italic; }
.night-owl-no-italics .mtkb { font-weight: bold; }
.night-owl-no-italics .mtku { text-decoration: underline; text-underline-position: under; }
`}</style>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      